import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useState } from "react"
import { StaticImage } from "gatsby-plugin-image"
import axios from "axios"
function StatsBackground({stats_dict}) {
    let [trait_count,setTraitCount]=useState({})

    function getTraitCount(){    
        axios.get("https://talking_tux_api.deta.dev/meta_data").then((data)=>{
            let background_count=data.data.data.background_count
            setTraitCount(background_count)
        })
    }
    React.useEffect(()=>{
        getTraitCount()
    },[])

    return ( trait_count && (
        <li className="col-span-2 flex shadow-sm rounded-md">
        <div className={`"flex-shrink-0 flex items-center justify-center w-16 ${stats_dict.bgclass} text-white text-sm font-medium rounded-l-md"`}>
            
        </div>
        <div className="flex-1 flex items-center justify-between border-t border-r border-b border-gray-200 bg-white rounded-r-md truncate">
            <div className="flex-1 px-4 py-2 text-sm truncate">
                <a href="#" className="text-gray-900 font-medium hover:text-gray-600">{stats_dict.label}</a>
             <p className="text-gray-500"> {trait_count[stats_dict.key_name]}</p>
            </div>
           
        </div>
    </li>)

    )
}

export default StatsBackground
