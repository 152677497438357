import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useState } from "react"
import { StaticImage } from "gatsby-plugin-image"
import StatsBackground from "./stats_background"
import StatsTux from "./stats_tux"
import StatsAsc from "./stats_asc"

function WhatisTux({ }) {
    let background_stats = [
        {
            label: "Blue Color",
            bgclass: "card-blue-bg",
       
            key_name:"Blue"
        },
        {
            label: "Orange Color",
            bgclass: "card-orange-bg",
     
            key_name:"Orange"
        },
        {
            label: "Gold Color",
            bgclass: "card-gold-bg",
        
            key_name:"Gold"
        },
        {
            label: "Pink Color",
            bgclass: "card-pink-bg",
          
            key_name:"Pink"
        },
        {
            label: "Yellow Color",
            bgclass: "card-yellow-bg",
        
            key_name:"Yellow"
        }
    ]
   
 
    return (
        <div className="relative bg-white  font-poppins">
            <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:gap-24 lg:items-start">
                <div className="relative sm:py-16 lg:py-0">
                    <div aria-hidden="true" className="hidden sm:block lg:absolute lg:inset-y-0 lg:right-0 lg:w-screen">
                        <div className="absolute inset-y-0 right-1/2 w-full bg-gray-50 rounded-r-3xl lg:right-72"></div>
                        <svg className="absolute top-8 left-1/2 -ml-3 lg:-right-8 lg:left-auto lg:top-12" width="404" height="392" fill="none" viewBox="0 0 404 392">
                            <defs>
                                <pattern id="02f20b47-fd69-4224-a62a-4c9de5c763f7" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                                    <rect x="0" y="0" width="4" height="4" className="text-gray-200" fill="currentColor" />
                                </pattern>
                            </defs>
                            <rect width="404" height="392" fill="url(#02f20b47-fd69-4224-a62a-4c9de5c763f7)" />
                        </svg>
                    </div>
                    <div className="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0 lg:max-w-none lg:py-20">
                        <StaticImage className="absoluteh-full w-full object-cover relative mx-auto" src="../images/tuxcards/7.png" alt="" />
                    </div>
                    
                </div>

                <div className="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0">

                   
                    <div className="pt-12 sm:pt-16 lg:pt-20">

                        <h2 className="text-6xl  font-extrabold tracking-tight sm:text-4xl bg-clip-text text-transparent  bg-gradient-to-r from-green-400 via-pink-500  to-blue-500">
                            Deck Of Tux
                        </h2>
                        <div className="mt-6 text-gray-500 space-y-6">

                            <p className="text-lg">
                                Deck of tux is an nft collection of <span className="font-extrabold tracking-tight sm:text-2xl bg-clip-text text-transparent  bg-gradient-to-r from-green-400 via-pink-500  to-blue-500">52</span> pixeleted Tux with various accessories which was generated from python script, all the items in the collections are unique. 
                                This project is an instance of generative art secured on the polygon network.
                              
                            </p>
{/* 
                            <p className="text-base leading-7">
                                All the cards are unique and have some specific properties

                            </p> */}

                        </div>
                    </div>


                    <div className="mt-10">
                        <dl className="grid grid-cols-2 gap-x-4 gap-y-8">
                            <div className="border-t-2 border-gray-100 pt-6">
                                <dt className="text-base font-medium bg-clip-text text-transparent  bg-gradient-to-r from-green-400 via-pink-500  to-blue-500">Total Minted Till Now</dt>
                                <dd className="text-3xl font-extrabold tracking-tight text-gray-900 bg-clip-text text-transparent  bg-gradient-to-r from-green-400 via-pink-500  to-blue-500">52</dd>
                            </div>

                            <div className="border-t-2 border-gray-100 pt-6">
                                <dt className="text-base font-medium bg-clip-text text-transparent  bg-gradient-to-r from-green-400 via-pink-500  to-blue-500">Total Sold</dt>
                                <dd className="text-3xl font-extrabold tracking-tight text-gray-900">1</dd>
                            </div>
                        </dl>
                        <h2 className="text-gray-500 text-xl font-medium uppercase tracking-wide mt-4">Backgrounds</h2>
                        <ul role="list" className="mt-3 grid grid-cols-1 gap-5 sm:gap-6 sm:grid-cols-2 lg:grid-cols-4">
                            {background_stats.map((item,i) => <StatsBackground key={i} stats_dict={item} />)}
                        </ul>

                        <h2 className="text-gray-500 text-xl font-medium uppercase tracking-wide mt-4">Tux</h2>
                        <StatsTux />
                        <h2 className="text-gray-500 text-xl font-medium uppercase tracking-wide mt-4">Accessories</h2>
                        <StatsAsc />




                        <div className="mt-10">
                            <div className="flex flex-row">
                                <h2 className="text-base font-medium bg-clip-text text-transparent  bg-gradient-to-r from-green-400 via-pink-500  to-blue-500">
                                    Contract Detail | <a target="_blank" href="https://polygonscan.com/address/0xeda11c2a89731d833ef82b1983235c10f8f8dc34"><span className="font-bold">0xeda11c2a89731d833ef82b1983235c10f8f8dc34</span></a>
                                </h2>

                            </div>

                        </div>
{/* 
                        <div className="mt-10">
                            <a href="#" className="text-base font-medium text-indigo-600"> Learn more about how we're changing the world <span aria-hidden="true">&rarr;</span> </a>
                        </div> */}
                    </div>
                </div>
            </div>
        
        </div>




    )

}

export default WhatisTux
