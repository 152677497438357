import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useState } from "react"
import { StaticImage } from "gatsby-plugin-image"
import tux_black from "../images/stats_tux/tux-black.png"
function StatsTux({ stats_dict }) {

    return (
        <ul role="list" className="mt-3 grid grid-cols-1 gap-5 sm:gap-6 sm:grid-cols-2 lg:grid-cols-4">

            <li className="col-span-2 flex shadow-sm rounded-md">
                <div className="flex-shrink-0">
                    <StaticImage className="w-10" src='../images/stats_tux/tux-black.png' alt="" />

                </div>
                <div className="flex-1 flex items-center justify-between border-t border-r border-b border-gray-200 bg-white rounded-r-md truncate">
                    <div className="flex-1 px-4 py-2 text-sm truncate">
                        <a href="#" className="text-gray-900 font-medium hover:text-gray-600">Black Tux</a>
                        <p className="text-gray-500">17</p>
                    </div>

                </div>
            </li>
            <li className="col-span-2 flex shadow-sm rounded-md">
                <div className="flex-shrink-0">
                    <StaticImage className="w-10" src='../images/stats_tux/tux-blue.png' alt="" />

                </div>
                <div className="flex-1 flex items-center justify-between border-t border-r border-b border-gray-200 bg-white rounded-r-md truncate">
                    <div className="flex-1 px-4 py-2 text-sm truncate">
                        <a href="#" className="text-gray-900 font-medium hover:text-gray-600">Blue Tux</a>
                        <p className="text-gray-500">15</p>
                    </div>

                </div>
            </li>
            <li className="col-span-2 flex shadow-sm rounded-md">
                <div className="flex-shrink-0">
                    <StaticImage className="w-10" src='../images/stats_tux/tux-cyan.png' alt="" />

                </div>
                <div className="flex-1 flex items-center justify-between border-t border-r border-b border-gray-200 bg-white rounded-r-md truncate">
                    <div className="flex-1 px-4 py-2 text-sm truncate">
                        <a href="#" className="text-gray-900 font-medium hover:text-gray-600">Cyan Tux</a>
                        <p className="text-gray-500">12</p>
                    </div>

                </div>
            </li>
            <li className="col-span-2 flex shadow-sm rounded-md">
                <div className="flex-shrink-0">
                    <StaticImage className="w-10" src='../images/stats_tux/tux-purple.png' alt="" />

                </div>
                <div className="flex-1 flex items-center justify-between border-t border-r border-b border-gray-200 bg-white rounded-r-md truncate">
                    <div className="flex-1 px-4 py-2 text-sm truncate">
                        <a href="#" className="text-gray-900 font-medium hover:text-gray-600">Purple Tux</a>
                        <p className="text-gray-500">8</p>
                    </div>

                </div>
            </li>
        </ul>

    )
}

export default StatsTux
